import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CustomResModel } from 'src/app/shared/models/custom.model';
import { PermissionsResModel } from 'src/app/shared/models/permissions.model';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';

const BACKEND_URL = environment.appUrl;
@Injectable({
  providedIn: 'root',
})
export class PermisionsService {
  private permissions: PermissionsResModel[];
  private permissionsObj = {} as { key: PermissionsResModel };
  private permisionsGotSubject = new Subject<boolean>();
  private notificationsListSubject = new Subject<boolean>();
  private endPoints = {
    getPermissions: 'Permission/GetPermissions',
    getUserPermissions: 'User/GetUserPermissions',
  };

  constructor(private httpClient: HttpClient) {}

  public getUserPermissions(): void {
    this.httpClient
      .get<CustomResModel<PermissionsResModel[]>>(BACKEND_URL + this.endPoints.getUserPermissions, {})
      .subscribe((res) => {
        if (!res.hasError) {
          this.permissions = res.data;
          this.arrayToObject();
          this.permisionsGotSubject.next(true);
        }
      });
  }

  public getPermissions(): void {
    this.httpClient
      .post<CustomResModel<PermissionsResModel[]>>(BACKEND_URL + this.endPoints.getPermissions, {})
      .subscribe((res) => {
        if (!res.hasError) {
          this.permissions = res.data;
          this.arrayToObject();
          this.permisionsGotSubject.next(true);
        }
      });
  }

  public permisionsGotListener(): Observable<boolean> {
    return this.permisionsGotSubject.asObservable();
  }

  public arrayToObject(): void {
    for (const perm of this.permissions) {
      this.permissionsObj[perm.name] = perm;
    }
  }

  public returnPermisionExist(key: string): boolean {
    if (this.permissionsObj[key]) {
      return true;
    }

    return false;
  }

  getNotifications() {
    this.httpClient.post(BACKEND_URL + 'CallBack/GetCallBacks', { skip: 1 }).subscribe((res: any) => {
      if (!res.hasError) {
        this.notificationsListSubject.next(res);
      }
    });
  }

  public notificationsListListener(): Observable<any> {
    return this.notificationsListSubject.asObservable();
  }
}
