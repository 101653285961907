import { AfterViewChecked, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SettingsService } from '../settings.service';

@Component({
  selector: 'app-editing-bar',
  templateUrl: './editing-bar.component.html',
  styleUrls: ['./editing-bar.component.scss'],
})
export class EditingBarComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Output() public changeFileEvent = new EventEmitter();
  @Output() public deleteFileEvent = new EventEmitter();
  @Input() public fileUrl: string;
  @Input() public fileName: string;
  @Input() public file: File = null;

  public addClass = false;

  constructor(private settingsService: SettingsService) {}

  ngOnInit(): void {}

  onCloseEditbar(): void {
    this.settingsService.toggleEditBar();
  }

  onChange(): void {
    this.changeFileEvent.emit(true);
  }

  onDelete(): void {
    this.deleteFileEvent.next(true);
  }

  ngAfterViewChecked(): void {
    this.addClass = true;
    (window as any).document.body.classList.add('scroll-disabled');
  }

  ngOnDestroy(): void {
    (window as any).document.body.classList.remove('scroll-disabled');
  }
}
