import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ToasterService, ToasterConfig } from 'angular2-toaster';
import { takeUntil } from 'rxjs/operators';

import { SuccessService } from '../success.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent implements OnInit, OnDestroy {
  public successMessage: string;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  toasterconfig: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-bottom-right',
    showCloseButton: true,
    timeout: 2000,
  });

  constructor(private toasterService: ToasterService, private successService: SuccessService) {}

  ngOnInit() {
    this.successService
      .getSuccessUpdateListener()
      .pipe(takeUntil(this.destroy$))
      .subscribe((text: string) => {
        this.successMessage = text;
        this.toasterService.pop('success', 'Удачно!', text);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
