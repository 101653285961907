<div class="overlay-settings" [class.active]="openedSettings">
  <div class="rightBar setting" [class.active]="openedSettings">
    <div class="rightBarInner">
      <div class="rightBarInnerHead">
        <span class="rightBarInnerHeadText">
          Настройки
        </span>
        <div class="closeBar " (click)="onCloseBar()">
          <img src="assets/icons/close.png" alt="" width="18.6px" height="18.6px">
        </div>
      </div>
      <div class="rightBarInnerBody">
        <div class="adminingPanel">
          <div class="adminingPanelHead">
            <span class="adminingPanelHeadText">
              Панель администрирования
            </span>
          </div>
          <div class="adminingPanelBody">
            <!-- <div class="checkBoxItem">
              <div class="leftSide">
                <div class="leftSideHead">
                  <span class="leftSideHeadText">
                    Тёмная тема
                  </span>
                </div>
                <div class="leftSideBody">
                  <span class="leftSideBodyText">
                    Внешний вид интерфейса ПА
                  </span>
                </div>
              </div>
              <div class="rightSide">
                <label class="switch" for="checkbox">
                  <input type="checkbox" id="checkbox" />
                  <div class="slider round"></div>
                </label>
              </div>
            </div> -->
            <div class="checkBoxItem">
              <div class="leftSide">
                <div class="leftSideHead">
                  <span class="leftSideHeadText">
                    Редактирование контента
                  </span>
                </div>
                <div class="leftSideBody">
                  <span class="leftSideBodyText">
                    Редактирование всех полей ПА
                  </span>
                </div>
              </div>
              <div class="rightSide">
                <label class="switch" for="edit">
                  <input type="checkbox" id="edit" [(ngModel)]="settings.contentEdit" (change)="settingsChanged()" />
                  <div class="slider round"></div>
                </label>
              </div>
            </div>

            <div class="customSelect" [class.active]="openedSelects.langs"
              (click)="openedSelects.langs = !openedSelects.langs" (clickOutside)="openedSelects.langs = false">
              <div class="customSelectHead">
                <span class="customSelectHeadInfo">
                  Язык ПА
                </span>
                {{ currentActiveLang }}
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 10L12 15L17 10H7Z" fill="black" />
                </svg>

              </div>
              <div class="customSelectBody">
                <div class="customSelectBodyItem" *ngFor="let lang of languages">
                  <span class="customSelectBodyItemText" (click)="onLangChanged(lang.code)">
                    {{ lang.name }}
                  </span>
                </div>
              </div>
            </div>
            <span class="selectInfo">
              Язык панели администрирования
            </span>
          </div>


          <div class="adminingPanelHead" *ngIf="siteSettings.length">
            <span class="adminingPanelHeadText">
              Сайт
            </span>
          </div>
          <div class="adminingPanelBody"
            *ngIf="siteSettings.length && siteSettings[0].key === 'EnableOrDisableTechnicalWorkPage'">
            <div class="checkBoxItem">
              <div class="leftSide">
                <div class="leftSideHead">
                  <span class="leftSideHeadText">
                    Технические работы
                  </span>
                </div>
                <div class="leftSideBody">
                  <span class="leftSideBodyText">
                    Режим тенических работ на сайте
                  </span>
                </div>
              </div>
              <div class="rightSide">
                <label class="switch" for="type">
                  <input type="checkbox" id="type" [(ngModel)]="siteSettings[0].value.IsEnabled"
                    (click)="settingChange(siteSettings[0])" />
                  <div class="slider round"></div>
                </label>
              </div>
            </div>
          </div>



        </div>
      </div>
    </div>
  </div>
</div>
