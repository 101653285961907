<div class="overlay-settings" [class.active]="addClass">
  <div class="rightBar" [class.active]="addClass">
    <div class="rightBarInner">
      <div class="rightBarInnerHead">
        <span class="rightBarInnerHeadText">
          Редактирование файла
        </span>
        <div class="closeBar" (click)="onCloseEditbar()">
          <img src="assets/icons/close.png" alt="" width="18.6px" height="18.6px">
        </div>
      </div>
      <div class="rightBarInnerBody">
        <div class="rightBarBodyImg">
          <div class="rightBarBodyImgWrap" *ngIf="fileUrl">
            <img [src]="fileUrl" />
          </div>
          <span class="imageInfo" *ngIf="file">
            Размер: {{ (file.size / 1024).toFixed(0) }} Кб
          </span>
          <div class="imgButtonWrap">
            <button class="buttonItem" (click)="onChange()">
              ЗАМЕНИТЬ
            </button>
          </div>
        </div>
        <div class="rightForm">
          <div class="field">
            <input type="text" [(ngModel)]="fileName" class="inputInner float disabled" name="fullname" id="fullname"
              placeholder="aa">
            <label for="fullname">Название файла</label>
          </div>
          <!-- <div class="field">
            <input type="text" class="inputInner float" name="fullname" id="sort" placeholder="aa">
            <label for="sort">Порядок в списке</label>
          </div> -->
          <div class="field">
            <input type="text" [(ngModel)]="fileUrl" class="inputInner float disabled" name="fullname" id="link"
              placeholder="aa">
            <label for="link">Ссылка на файл</label>
          </div>
        </div>
        <div class="bottomRight">
          <button class="buttonItem red" (click)="onDelete()">
            УДАЛИТЬ
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
