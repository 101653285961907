import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppSettingsModel } from '../models/app-settings.model';

import { SettingsService } from '../settings.service';

@Component({
  selector: 'app-settings-bar',
  templateUrl: './settings-bar.component.html',
  styleUrls: ['./settings-bar.component.scss'],
})
export class SettingsBarComponent implements OnInit, OnDestroy {
  public openedSettings = false;
  public openedSelects: any = {};
  public currentActiveLang: string;
  public languages = [
    { code: 'ru', name: 'Русский' },
    { code: 'en', name: 'English' },
  ];
  public siteSettings = [];
  public settings: AppSettingsModel = { contentEdit: true };

  private destroy$ = new Subject<boolean>();

  constructor(private settingsService: SettingsService, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.settingsService.WebSettings();
    this.currentActiveLang = this.chooseLangWithCode(
      this.translateService.currentLang || this.translateService.defaultLang
    );
    this.settingsService
      .WebSettingsListener()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.siteSettings = res.data;
        this.siteSettings.map((set) => {
          set.value = JSON.parse(set.value);
        });
      });

    this.settingsService
      .toggleSettingsListener()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: boolean) => {
        this.openedSettings = res;
        if (this.openedSettings) {
          (window as any).document.body.classList.add('scroll-disabled');
        } else {
          (window as any).document.body.classList.remove('scroll-disabled');
        }
      });

    this.settingsService
      .getSettingsListener()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: AppSettingsModel) => {
        if (res) {
          this.settings = res;
        }
      });
  }

  settingChange(event) {
    this.settingsService.EnableOrDisableTechWorkPage({ IsEnable: !event.value.IsEnabled });
  }

  public chooseLangWithCode(code: string): string {
    let langStr = null;
    this.languages.map((lang) => {
      if (code === lang.code) {
        langStr = lang.name;
      }
    });
    return langStr;
  }

  public onCloseBar(): void {
    this.settingsService.toggleSettings();
  }

  public settingsChanged(): void {
    this.settingsService.editSettings(this.settings);
  }

  public onLangChanged(code: string): void {
    this.translateService.use(code);
    this.currentActiveLang = this.chooseLangWithCode(code);
    this.settingsService.editSettings({ language: code });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
