import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './auth/auth.service';
import { SitePagesProxyService } from './pages/site-pages/site-pages-proxy.service';
import { MainObjectsReqModel } from './shared/models/mainObjects.model';
import { PermisionsService } from './shared/permisions.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'CMS';
  public isLoading = true;
  constructor(
    private translateService: TranslateService,
    private permService: PermisionsService,
    private authService: AuthService,
    private siteProxy: SitePagesProxyService
  ) {
    this.translateService.addLangs(['en', 'ru']);
    this.translateService.setDefaultLang(window.localStorage.getItem('lang') || 'ru');
    if (this.authService.isAuth()) {
      this.permService.getUserPermissions();
    } else {
      this.isLoading = false;
    }
  }

  ngOnInit(): void {
    this.permService.permisionsGotListener().subscribe((res) => {
      if (res) {
        if (this.permService.returnPermisionExist('GetMainObjectTypes')) {
          this.siteProxy.getMainObjectTypes({ isDeleted: false, hasAParent: false } as MainObjectsReqModel, true);
        }
        this.isLoading = false;
      }
    });
  }
}
