import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { EditingBarComponent } from './editing-bar/editing-bar.component';
import { SettingsBarComponent } from './settings-bar/settings-bar.component';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule } from '@progress/kendo-angular-grid';
import { ClickOutsideDirective } from 'src/core/directives/click_outside.directive';
import { FormsModule } from '@angular/forms';
import { DndDirective } from './directives/dnd.directive';

@NgModule({
  declarations: [UploadFileComponent, EditingBarComponent, SettingsBarComponent, ClickOutsideDirective, DndDirective],
  imports: [CommonModule, ButtonsModule, GridModule, GridModule, FormsModule],
  providers: [],
  bootstrap: [],
  exports: [UploadFileComponent, EditingBarComponent, SettingsBarComponent, ClickOutsideDirective, GridModule],
})
export class SharedModule {}
