export class LoginReqModel {
  userName: string;
  password: string;
}

export interface LoginResModel {
  token: string;
  userId: number;
  userName: string;
  roleName: string;
}
