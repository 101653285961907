import { HttpInterceptor, HttpRequest, HttpHandler, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Injectable } from '@angular/core';

import { ErrorService } from './error.service';
import { AuthService } from '../auth/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private errorService: ErrorService,
    private authService: AuthService,
    private translate: TranslateService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse && event.body.hasError) {
            event.body.errors.map((err) => {
              if (err.code === 2 || err.code === 5) {
                this.authService.logoutUserOffline();
              }
              this.errorService.setError(JSON.parse(err.description)['ru']);
              return throwError(err);
            });
          }
        },
        (error) => {
          const errorMessage = error.statusText;
          this.errorService.setError(errorMessage);
          return throwError(error);
        }
      )
    );
  }
}
