import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { CustomResModel } from 'src/app/shared/models/custom.model';
import { AppSettingsModel } from './models/app-settings.model';
import { environment } from 'src/environments/environment';
const BACKEND_URL = environment.appUrl;

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private settings = { contentEdit: true } as AppSettingsModel;
  private settingsSubject = new BehaviorSubject<AppSettingsModel>(null);

  private settingsBarOpened = false;
  private settingsBarOpenedSubject = new Subject<boolean>();
  private uploadPopupOpened = false;
  private uploadPopupOpenedSubject = new Subject<any>();
  private editBarOpened = false;
  private editBarOpenedSubject = new Subject<boolean>();
  private GetWebSettingsSubject = new Subject<any>();

  constructor(private httpClient: HttpClient) {}
  endPoints = {
    webSettings: 'WebSettings/GetWebSettings',
    EnableOrDisableTechWorkPage: 'WebSettings/EnableOrDisableTechnicalWorkPage',
    GetFileKeys: 'MainObjectType/GetFileKeys',
  };

  public toggleSettings(): void {
    this.settingsBarOpened = !this.settingsBarOpened;
    this.settingsBarOpenedSubject.next(this.settingsBarOpened);
  }

  public toggleSettingsListener(): Observable<boolean> {
    return this.settingsBarOpenedSubject.asObservable();
  }

  public editSettings(settings: AppSettingsModel): void {
    this.settings = { ...this.settings, ...settings };
    this.settingsSubject.next(this.settings);
  }

  public getSettingsListener(): Observable<AppSettingsModel> {
    return this.settingsSubject.asObservable();
  }

  public toggleuploadPopup(method: string): void {
    this.uploadPopupOpened = !this.uploadPopupOpened;
    this.uploadPopupOpenedSubject.next({ state: this.uploadPopupOpened, method });
  }

  public toggleuploadPopupListener(): Observable<any> {
    return this.uploadPopupOpenedSubject.asObservable();
  }

  public toggleEditBar(): void {
    this.editBarOpened = !this.editBarOpened;
    this.editBarOpenedSubject.next(this.editBarOpened);
  }

  public toggleEditBarListener(): Observable<boolean> {
    return this.editBarOpenedSubject.asObservable();
  }

  GetFileKeys() {
    return this.httpClient.get(BACKEND_URL + this.endPoints.GetFileKeys);
  }
  WebSettings() {
    this.httpClient.get(BACKEND_URL + this.endPoints.webSettings).subscribe((res: any) => {
      if (!res.hasError) {
        this.GetWebSettingsSubject.next(res);
      }
    });
  }

  EnableOrDisableTechWorkPage(data) {
    this.httpClient.post(BACKEND_URL + this.endPoints.EnableOrDisableTechWorkPage, data).subscribe((res: any) => {
      if (!res.hasError) {
        this.WebSettings();
      }
    });
  }

  public WebSettingsListener(): Observable<any> {
    return this.GetWebSettingsSubject.asObservable();
  }
}
