import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private errorMessage = new Subject<string>();

  constructor() { }

  public setError(text: string): void {
    this.errorMessage.next(text);
  }

  public getErrorUpdateListener(): Observable<string> {
    return this.errorMessage.asObservable();
  }
}
