<div class="popup">
  <div class="close" (click)="onClosePop()">
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <path
          d="M39.3125 12.5625L31.875 20L39.3125 27.4375L37.4375 29.3125L30 21.875L22.5625 29.3125L20.6875 27.4375L28.125 20L20.6875 12.5625L22.5625 10.6875L30 18.125L37.4375 10.6875L39.3125 12.5625Z"
          fill="#FAFAFA" fill-opacity="0.8" />
      </g>
      <defs>
        <filter id="filter0_d" x="0.6875" y="0.6875" width="58.625" height="58.625" filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="10" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>


  </div>
  <div class="popupWrap" appDnd (fileDropped)="handleFileInput($event)">
    <div class="popupInner">
      <div class="popupInnerHead">
        <span class="popupInnerHeadText">
          Перетащите файлы для загрузки
        </span>
      </div>
      <div class="popupInnerBody">
        <div class="buttonWrap">

          <button class="buttonItem">
            ЗАГРУЗИТЬ ФАЙЛ
          </button>
        </div>
        <div class="uploadInfo">
          <span class="uploadInfoText">
            Доступные форматы .jpg, .png, .gif
            Оптимальный размер изображения 600х600px
            Размер файла не должен превышать 3 Мб
          </span>
        </div>
      </div>

      <input type="file" class="invisible-file" #fileInput (change)="handleFileInput($event.target.files)">

    </div>
  </div>
</div>
