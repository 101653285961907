import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ToasterService, ToasterConfig } from 'angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

import { ErrorService } from '../error.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit, OnDestroy {
  public errorMessage: string;
  private currentLang: string;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  toasterconfig: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-bottom-right',
    timeout: 2000,
    showCloseButton: true,
  });

  constructor(
    private toasterService: ToasterService,
    private errorService: ErrorService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.currentLang = this.translate.currentLang;
    this.errorService
      .getErrorUpdateListener()
      .pipe(takeUntil(this.destroy$))
      .subscribe((text: string) => {
        this.errorMessage = text;
        this.toasterService.pop('error', this.currentLang === 'ru' ? 'Ошибка!' : 'Error!', text);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
