import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { CustomInnerModel, CustomResModel } from 'src/app/shared/models/custom.model';
import {
  AddMainObjectsReqModel,
  MainObjectsReqModel,
  MainObjectsResModel,
  UpdateMainObjectsReqModel,
} from 'src/app/shared/models/mainObjects.model';
import { environment } from 'src/environments/environment';

const BACKEND_URL = environment.appUrl;

@Injectable({
  providedIn: 'root',
})
export class SitePagesProxyService {
  private endPoints = {
    getMainObjectTypes: 'MainObjectType/GetMainObjectTypes',
    getMainObjects: 'MainObject/GetMainObjects',
    updateMainObject: 'MainObject/UpdateMainObject',
    addMainObject: 'MainObject/addMainObject',
    deleteMainObject: 'MainObject/deleteMainObject',
    createTreeCopy: 'MainObject/CreateTreeCopy',
  };

  private getMainObjectTypesSubject = new Subject<CustomResModel<CustomInnerModel<MainObjectsResModel>>>();
  private getMainObjectTypesForSidebarSubject = new Subject<CustomResModel<CustomInnerModel<MainObjectsResModel>>>();
  private getMainObjectsSubject = new Subject<CustomResModel<CustomInnerModel<MainObjectsResModel>>>();
  private updateMainObjectSubject = new Subject<CustomResModel<any>>();
  private addMainObjectSubject = new Subject<CustomResModel<any>>();
  private deleteMainObjectSubject = new Subject<CustomResModel<any>>();
  private createTreeCopySubject = new Subject<CustomResModel<any>>();

  constructor(private httpClient: HttpClient) {}

  public getMainObjectTypes(model: MainObjectsReqModel, forSidebar?: boolean, isOneType?: boolean): void {
    this.httpClient
      .post<CustomResModel<CustomInnerModel<MainObjectsResModel>>>(
        BACKEND_URL + this.endPoints.getMainObjectTypes,
        model
      )
      .subscribe((res) => {
        if (!res.hasError) {
          if (forSidebar) {
            // this.getMainObjectTypesForSidebarSubject.next(res);
          } else {
            if (isOneType) {
              res.data.additionalInfo = true;
            }
            this.getMainObjectTypesSubject.next(res);
          }
        }
      });
  }

  getMainObjectTypesforSidebar() {
    this.httpClient
      .post<CustomResModel<CustomInnerModel<MainObjectsResModel>>>(BACKEND_URL + this.endPoints.getMainObjectTypes, {
        isDeleted: false,
      })
      .subscribe((res) => {
        this.getMainObjectTypesForSidebarSubject.next(res);
      });
  }

  public getMainObjectTypesforSidebarListener() {
    return this.getMainObjectTypesForSidebarSubject.asObservable();
  }

  public getMainObjectTypesListener(): Observable<CustomResModel<CustomInnerModel<MainObjectsResModel>>> {
    return this.getMainObjectTypesSubject.asObservable();
  }

  public getMainObjects(model: MainObjectsReqModel): void {
    this.httpClient
      .post<CustomResModel<CustomInnerModel<MainObjectsResModel>>>(BACKEND_URL + this.endPoints.getMainObjects, model)
      .subscribe((res) => {
        if (!res.hasError) {
          this.getMainObjectsSubject.next(res);
        }
      });
  }

  public getMainObjectsListener(): Observable<CustomResModel<CustomInnerModel<MainObjectsResModel>>> {
    return this.getMainObjectsSubject.asObservable();
  }

  public getChildObjectTree(
    model: MainObjectsReqModel
  ): Observable<CustomResModel<CustomInnerModel<MainObjectsResModel>>> {
    return this.httpClient.post<CustomResModel<CustomInnerModel<MainObjectsResModel>>>(
      BACKEND_URL + this.endPoints.getMainObjects,
      model
    );
  }

  public updateMainObject(model: UpdateMainObjectsReqModel): void {
    this.httpClient
      .post<CustomResModel<MainObjectsResModel>>(BACKEND_URL + this.endPoints.updateMainObject, model)
      .subscribe((res) => {
        if (!res.hasError) {
          this.updateMainObjectSubject.next(res);
        }
      });
  }

  public updateMainObjectListener(): Observable<CustomResModel<MainObjectsResModel>> {
    return this.updateMainObjectSubject.asObservable();
  }

  public addMainObject(model: AddMainObjectsReqModel): void {
    this.httpClient
      .post<CustomResModel<MainObjectsResModel>>(BACKEND_URL + this.endPoints.addMainObject, model)
      .subscribe((res) => {
        if (!res.hasError) {
          this.addMainObjectSubject.next(res);
        }
      });
  }

  public addMainObjecttListener(): Observable<CustomResModel<MainObjectsResModel>> {
    return this.addMainObjectSubject.asObservable();
  }

  public deleteMainObject(model: { id: number }): void {
    this.httpClient.post<CustomResModel<any>>(BACKEND_URL + this.endPoints.deleteMainObject, model).subscribe((res) => {
      if (!res.hasError) {
        this.deleteMainObjectSubject.next(res);
      }
    });
  }

  public deleteMainObjectListener(): Observable<CustomResModel<any>> {
    return this.deleteMainObjectSubject.asObservable();
  }

  public createTreeCopy(model: { id: number }): void {
    this.httpClient.post<CustomResModel<any>>(BACKEND_URL + this.endPoints.createTreeCopy, model).subscribe((res) => {
      if (!res.hasError) {
        this.createTreeCopySubject.next(res);
      }
    });
  }

  public createTreeCopytListener(): Observable<CustomResModel<any>> {
    return this.createTreeCopySubject.asObservable();
  }
}
