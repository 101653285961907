import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { LoginReqModel, LoginResModel } from '../shared/models/login.model';
import { environment } from '../../environments/environment';
import { CustomResModel } from '../shared/models/custom.model';
import { PermisionsService } from '../shared/permisions.service';

const BACKEND_URL = environment.appUrl;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private token: string;
  private userId: number;
  private userName: string;
  private roleName: string;
  private isAuthenticated = false;
  private userAuthenSubject = new Subject<boolean>();
  public LoginEvent = new Subject<boolean>();
  private loginErrorSubject = new Subject<any>();

  private appUrls = {
    login: 'User/Login',
    logout: 'User/LogOut',
  };

  constructor(private httpClient: HttpClient, private router: Router, private permService: PermisionsService) {}

  public loginUser(loginModel: LoginReqModel): void {
    this.httpClient.post<CustomResModel<LoginResModel>>(BACKEND_URL + this.appUrls.login, loginModel).subscribe(
      (response) => {
        if (response.hasError) {
          this.loginErrorSubject.next(response);
          this.userAuthenSubject.next(false);
          return;
        }
        this.token = response.data.token;
        this.userId = response.data.userId;
        this.userName = response.data.userName;
        this.roleName = response.data.roleName;
        if (this.token) {
          this.isAuthenticated = true;
          this.userAuthenSubject.next(true);
          this.LoginEvent.next(true);
          this.saveInLocalStorage(this.token, this.userId, this.userName, this.roleName);
          this.router.navigate(['/pages/permissions/staff']);
          this.permService.getPermissions();
        }
      },
      (error) => {
        this.userAuthenSubject.next(false);
        this.loginErrorSubject.next(error);
      }
    );
  }

  public loginErrorListener(): Observable<any> {
    return this.loginErrorSubject.asObservable();
  }

  public logoutUser(): void {
    this.httpClient.post(BACKEND_URL + this.appUrls.logout, {}).subscribe((response) => {});
    this.token = null;
    this.isAuthenticated = false;
    this.userAuthenSubject.next(false);
    this.removeFromLocalStorage();
    this.router.navigate(['/']);
  }

  public logoutUserOffline(): void {
    this.token = null;
    this.isAuthenticated = false;
    this.userAuthenSubject.next(false);
    this.removeFromLocalStorage();
    this.router.navigate(['/']);
  }

  public getToken(): string {
    return this.token;
  }

  public getUserId(): number {
    return this.userId;
  }

  public getUserStatus(): Observable<boolean> {
    return this.userAuthenSubject.asObservable();
  }

  public getIsAuth(): boolean {
    return this.isAuth();
  }

  public isAuth(): boolean {
    const isAuth = this.getAuthData();
    if (isAuth) {
      this.token = isAuth.token;
      this.userId = Number(isAuth.userId);
      this.isAuthenticated = true;
      this.userAuthenSubject.next(true);
      return true;
    }
  }

  private saveInLocalStorage(token: string, userId: number, userName: string, roleName: string) {
    localStorage.setItem('token', token);
    localStorage.setItem('userId', userId.toString());
    localStorage.setItem('userName', userName.toString());
    localStorage.setItem('roleName', roleName.toString());
  }

  public removeFromLocalStorage() {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    localStorage.removeItem('roleName');
  }

  private getAuthData(): { token: string; userId: string } {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');

    if (!token && !userId) {
      return;
    }

    return { token, userId };
  }
}
