import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SuccessService {
  private successSubject = new Subject<string>();

  constructor() { }

  public setSuccess(text) {
    this.successSubject.next(text);
  }

  public getSuccessUpdateListener(): Observable<string> {
    return this.successSubject.asObservable();
  }
}
