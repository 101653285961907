import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '../auth.service';
import { LoginReqModel } from '../../shared/models/login.model';
import { environment } from '../../../environments/environment';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewChecked, OnDestroy {
  public submitted = false;
  public selectLang = false;
  public interval;
  public selectedLang = 'Русский';
  public lang = 'ru';
  public inputType = 'password';
  public languages = [
    { name: 'Русский', lang: 'ru' },
    { name: 'English', lang: 'en' },
  ];
  public model = new LoginReqModel();
  public environment = environment;
  public error = false;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('loginForm', { static: true }) private loginForm: NgForm;

  constructor(
    private translate: TranslateService,
    public authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.lang = window.localStorage.getItem('lang') || 'ru';
    window.localStorage.setItem('lang', this.lang);

    this.authService
      .loginErrorListener()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.error = true;
      });

    this.loginForm.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.error = false;
    });
  }

  ngAfterViewChecked(): void {
    this.inputType = 'text';
  }

  private markFormGroupTouched(formGroup): void {
    (Object as any).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  useLanguage(lang, name) {
    this.selectedLang = name;
    this.translate.use(lang);
    this.lang = lang;
    window.localStorage.setItem('lang', this.lang);
  }

  onSubmit(form: NgForm): void {
    this.markFormGroupTouched(form);
    if (form.invalid) {
      this.error = true;
      return;
    }
    this.submitted = true;
    this.authService.loginUser(this.model);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
