import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SettingsService } from '../settings.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent implements OnInit {
  @Input() public method: string;
  @Output() public uploadFileEvent = new EventEmitter();

  constructor(private settingsService: SettingsService) {}

  ngOnInit(): void {}

  onClosePop(): void {
    this.settingsService.toggleuploadPopup(this.method);
  }

  handleFileInput(file): void {
    this.uploadFileEvent.emit(file);
  }
}
