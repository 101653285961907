export const environment = {
  production: false,
  //   Testain
  // appUrl: 'https://innadminapi.innline.am/api/',
  // appRootUrl: 'https://innadminapi.innline.am/api/',
  // title:"Innline"

  // appUrl: 'https://izmadminapi.innline.am/api/',
  // appRootUrl: 'https://izmadminapi.innline.am/',
  // title:"Измайловский"

  appUrl: 'https://vchapi.innline.am/api/',
  appRootUrl: 'https://vchapi.innline.am/',
  title: 'Vchamp',

  // appUrl: 'https://pkcapi.innline.am/api/',
  // appRootUrl: 'https://pkcapi.innline.am/',
  // title:  "Parking City"

  // appUrl: 'https://normaapi.innline.am/api/',
  // appRootUrl: 'https://normaapi.innline.am',
  // title: 'Норма',

  // appUrl: 'https://miradminapi.innline.am/api/',
  // appRootUrl: 'https://miradminapi.innline.am/',
  // title: 'Mirror Glass Redesign',

  // appUrl: 'https://mosadminapi.innline.am/api/',
  // appRootUrl: 'https://mosadminapi.innline.am/',
  // title:"Мосфильмовский"

  // appUrl: 'https://pladminapi.innline.am/api/',
  // appRootUrl: 'https://pladminapi.innline.am',
  // title: 'Plast Imperial Redesign',

  /////////////////////// real /////////////////////////////

  // appUrl: 'https://api.vchamp.ru/api/',
  // appRootUrl: 'https://api.vchamp.ru',
  // title: 'Vchamp',

  // appUrl: 'https://api.izmaylovskiy.ru/api/',
  // appRootUrl: 'https://api.izmaylovskiy.ru',
  // title: 'Измайловский Админ',

  // appUrl: 'https://adminapi.tcmosfilmovskiy.ru/api/',
  // appRootUrl: 'https://adminapi.tcmosfilmovskiy.ru',
  // title: 'Мосфильмовский Админ',

  // appUrl: 'https://adminwebapi.parkking.city/api/',
  // appRootUrl: 'https://adminwebapi.parkking.city',
  // title: 'Parking City Админ',

  // appUrl: 'https://adminapi.pknorma.ru/api/',
  // appRootUrl: 'https://adminapi.pknorma.ru',
  // title: 'Норма Админ',

  // appUrl: 'https://adminapi.zerglass.ru/api/',
  // appRootUrl: 'https://adminapi.zerglass.ru',
  // title: 'Mirror Glass Redesign',

  // appUrl: 'https://adminapi.plastimperial.ru/api/',
  // appRootUrl: 'https://adminapi.plastimperial.ru',
  // title: 'Plast Imperial Redesign',
};
